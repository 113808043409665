import React from "react"

import { Container } from 'reactstrap' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-complaints' 
import worldIcon from '../assets/images/world.png'
import truckAbsolute from '../assets/images/complaints.png' 
 
export default () => (
  <Layout>
    <SEO title="Complations" />
    <Slider/>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	
	<div className="point-crossed-background2" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<h4 className="credtiH">{'COMPLAINTS'}</h4>  
				<img className="truckAbsolute2" src={truckAbsolute} alt="truck"/> 
			  </div> 
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> 
				<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 whiteBack"> 
					<p className="grayP">{'We are very sorry that you had a problem with your cargo.'}</p> 
					<p className="grayText2">{'To give you a solution as soon as possible download the document '+
											   'shown below and send it ot info@gutierreztrucking.com with the '+
											   'required information, a member of our team will communicate with you as soon as posible.' 
											   }</p> 
					<a href="#" className="locationVisit left">{'Document'}</a> 
				</div>
			  </div> 
			</div>
		</Container>
	</div>  
  </Layout>
)
